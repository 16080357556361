import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer 
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer  </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              
            <p className="playfair text-black text-xl md:text-3xl">About Cancer</p><br/>
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Sanskrit/Vedic name:
                  </p>{" "}
                  Karka
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Cancer Meaning:
                  </p>{" "}
                  The Crabs
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Type:{" "}
                  </p>
                  Water-Cardinal-Negative
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Exaltation:{" "}
                  </p>
                  The sun <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Fall:
                  </p>{" "}
                  The sun <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Cancer Sign</p>
                  <br />
                  Cancer is a zodiac sign that describes the personality of a
                  person whose life plans are not aligned with their natural
                  inclinations or passions. <br />
                  <br />
                  The name Cancer comes from the Greek word kailiks, which means
                  “to stab,” indicating the person is not in harmony with their
                  life’s direction. Zodiac personality traits is the principle
                  of predicting the character’s behavior. In the animal world,
                  the zodiac is a pattern of alignment between the animal and
                  its environment.
                  <br />
                  <br />
                  Cancer is the fourth sign of the zodiac that loves to run deep
                  in the zodiac circle. It is a feminine sign and archetype of a
                  mother. It is a tender sign moved by feelings and desires of
                  affection.
                  <br />
                  <br />
                  It is a sign of awakening. It is a sign of the zodiac that is
                  both strong and weak. It is a sign of love and desire. It is a
                  sign of passion and desire.
                  <br />
                  <br />
                  It is a sign that is often moved by egotism. It is a sign that
                  is usually only for women. It is a sign that one loves to be
                  kind to others. It is a sign that they are often content with
                  the company of people who can make them feel safe and secure.
                  <br />
                  <br />
                  It is also one of the signs of a powerful ruler. It is the
                  sign of an ambitious, innovative, and cautious person who
                  enjoys the challenge of all sorts of tasks. It is a brave and
                  robust sign that can handle any job. Cancer people are
                  motivated by their faith beliefs, as well as their family and
                  friends.
                  <br />
                  <br />
                  To know more about your horoscope or want your personalized
                  janampatri from our best astrologers to understand life
                  in-depth.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    How to recognize Cancer?
                  </p>
                  <br></br>
                  Cancer natives are caring and matriarchal, susceptible, and a
                  little dependent. They are the nurturer of the zodiac circle
                  who are mightily faithful and loving. They are complex and
                  insensitive from the exterior but very soft and sensitive from
                  the inside.
                  <br />
                  <br />
                  They are spiritual and sensitive, containing a high percentage
                  of spiritualism. They are free from superstition and are very
                  suspicious of it. They are very secretive and highly
                  suspicious of their neighbors. They are very temperamental and
                  secretive. Their sensitivity range from a very positive to a
                  very negative. They are hard to find and hard to hurt. They
                  are strong and hardy, and they have a long, very long life.
                  they are very romantic in nature.
                  <br />
                  <br />
                  They usually cry because of their fragile and vulnerable
                  heart. They offer empathy and can sit for long hours with
                  closed ones. Typically, they are one step forward and two
                  steps back. They intensely live in the past in their memories
                  and the future in their imaginations. They fiercely hold
                  themselves and their dear ones. they are always trying to
                  escape from the past and the future.
                  <br />
                  <br />
                  They are trained to be tough but soft and gentle. They are
                  powerful, brave, and self-assured. They are the kind of people
                  who are capable of being what they dream, what they want. They
                  are always in the moment, and the day is never done for them.
                  They are shy when it comes to express. They are attached to
                  their own stories and their love. They are often not very
                  clear about the consequences of their actions. They are known
                  for their strong and generous nature.
                  <br />
                  <br />
                  Cancer natives synchronize their passing moods to waning and
                  waxing of the Moon and hence possess the mysterious influence
                  of it. They keep on fluctuating highs and lows. They are
                  constant on inconsistency and possess dependable periodicity.
                  Funny Cancerians giggle and gurgle very loudly and can laugh
                  for a minute continuously. They come up with good jokes and
                  their funny side is startling and pops up incongruously from
                  their quiet and gentle personality.
                  <br />
                  <br />
                  Cancer natives synchronize their passing moods to the waning
                  and waxing of the Moon and hence possess the mysterious
                  influence. Because of their mood-changing behavior, they keep
                  on fluctuating highs and lows. They are stable in
                  inconsistency and contain trustworthy periodicity. Funny
                  Cancerians giggle and splash very loudly and can laugh for a
                  minute.
                  <br />
                  <br />
                  By nature, they are hilarious and humorous. People loved to
                  sit with them because they always light the mood of others
                  with their witty jokes. They always come up with good jokes,
                  and their funny side is startling and pops up incongruously
                  from their calm and soft personality.
                  <br />
                  <br />
                  They have an uncanny sense of publicity. It is only noticed
                  when they are pleased. They secretly enjoy the attention. They
                  will soak up all kinds of headlines they get. They won’t
                  pursue fame with passion but won’t shrink from it. They
                  sometimes give a cranky answer and come up with occasional
                  crabby spells when disappointed. And they are moods by their
                  nature; everything depends on the mood. When this
                  disappointment gets over, they come back with their sweet,
                  gentle, and understanding selves.
                  <br />
                  <br />
                  When it comes to relationship, they are not like playboy or
                  flirting randomly, and they are devoted to the person whom
                  they love the most; their liability, loyalty, and honesty are
                  entirely relied on by that person because love, family is
                  everything to them, they never compromise on their genuine
                  relations. If they are attached to anybody, they are always
                  beside that person no matter what happens.
                  <br />
                  <br />
                  There are other secret traits that a Cancerian can explore!
                  Ask our expert astrologers!
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    All About Cancer
                  </p>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Cancer Zodiac Date Range:
                  </p>{" "}
                  June 21 - July 22
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Domicile:
                  </p>{" "}
                  Moon
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Detriment:
                  </p>{" "}
                  Saturn
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Exaltation:
                  </p>{" "}
                  Jupiter <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Fall:
                  </p>{" "}
                  Mars <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Element:
                  </p>{" "}
                  Water
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Quality:
                  </p>{" "}
                  Cardinal
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Symbol:
                  </p>{" "}
                  The Crabs
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Tarot card:
                  </p>{" "}
                  The Chariot <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Fellow signs:{" "}
                  </p>
                  Scorpio, Pisces <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Compatible signs:
                  </p>{" "}
                  Taurus, Virgo, Scorpio, Pisces
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Most Compatible Signs:{" "}
                  </p>
                  Scorpio
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Opposite Signs:
                  </p>{" "}
                  Capricorn
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    The Symbol denotes:{" "}
                  </p>{" "}
                  Possession, retention and nurturing. It also symbolizes
                  motherhood.
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Characteristics:{" "}
                  </p>{" "}
                  Cold, phlegmatic, feminine
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Noteworthy qualities:{" "}
                  </p>
                  Patience, tenacity, domesticity, kindness, devotion,
                  versatility, adaptability, patriotism, sociability and
                  maternity.
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Undesirable personality traits:{" "}
                  </p>{" "}
                  Untruthfulness, unpredictability, sentimental, pride,
                  untidiness, resentment, vanity and laziness.
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Favorable things:
                  </p>{" "}
                  hosting parties, gourmet meals, intramural sports, museums and
                  art galleries, playing and working with kids
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Things they hate:
                  </p>{" "}
                  Tacky clothes, public speaking, frozen dinner, being rushed,
                  paying full price
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Birthstone:
                  </p>{" "}
                  Moonstone <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky stones:{" "}
                  </p>
                  Ruby, Pearl, Emerald, Jade, Green Onyx
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky Metals:{" "}
                  </p>
                  Silver
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky day:
                  </p>{" "}
                  Monday
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky number:{" "}
                  </p>
                  2, 7, 11, 16, 20, 25
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Birth Colours:
                  </p>{" "}
                  Silver, white, sea-green, light blue, puce
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Deepest need:{" "}
                  </p>
                  A harmonious home and family life
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lifetime wish:
                  </p>{" "}
                  To take care of their friends and family
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Mantra for life:{" "}
                  </p>
                  I feel
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    The quality most needed for balance:{" "}
                  </p>
                  Try to control your mood swings
                  <br />
                  <br />
           
              
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
